import React from 'react';

const Dashboard = React.lazy(() => import('pages/Dashboard'));
const Home = React.lazy(() => import('pages/Home'));
const Login = React.lazy(() => import('pages/Login'));
const Verification = React.lazy(() => import('pages/Verification'));
const Register = React.lazy(() => import('pages/Register'));
const ForgotPassword = React.lazy(() => import('pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('pages/ResetPassword'));
const Profile = React.lazy(() => import('pages/Dashboard/Profile'));
const CreatePost = React.lazy(() => import('pages/Dashboard/CreatePost'));
const ArticleDetails = React.lazy(() => import('pages/Articles/Details'));
const ArticleList = React.lazy(() => import('pages/Articles/List'));

const ArtworkDetails = React.lazy(() => import('pages/Artwork/Details'));
const ArtworkList = React.lazy(() => import('pages/Artwork/List'));
const Puzzles = React.lazy(() => import('pages/Puzzles'));
const Archives = React.lazy(() => import('pages/Archives'));
const PublicProfile = React.lazy(() => import('pages/PublicProfile'));
const Message = React.lazy(() => import('pages/Messages'));
const StartMsg = React.lazy(() => import('pages/Messages/StartMsg'));
const ReplyMsg = React.lazy(() => import('pages/Messages/ReplyMsg'));
const Reply = React.lazy(() => import('pages/Messages/Reply'));
const Faq = React.lazy(() => import('pages/Faq'));
const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicy'));
const ShippingPolicy = React.lazy(() => import('pages/ShippingPolicy'));
const RefundPolicy = React.lazy(() => import('pages/RefundPolicy'));
const Terms = React.lazy(() => import('pages/Terms'));
const AboutUs = React.lazy(() => import('pages/AboutUs'));
const WorkWithus = React.lazy(() => import('pages/WorkWithus'));
const Contact = React.lazy(() => import('pages/Contact'));
const Announcements = React.lazy(() => import('pages/Announcements'));
const Datadeletion = React.lazy(() => import('pages/DataDeletion'));
const Purchases = React.lazy(() => import('pages/Purchases'));
const Socialmedia = React.lazy(() => import('pages/Socialmedia'));
const Events = React.lazy(() => import('pages/Events'));
const EventsDetail = React.lazy(() => import("pages/Events/Details"));






export const routes = [
  {
    link: '/dashboard/:tab(articles|artworks|followers|following|history|bookmarks|downloads|rewards|subscriptions|goingson)',
    label: 'Dashboard',
    component: Dashboard,
  },
  {
    link: '/profile/:tab(update|resetpassword)',
    label: 'Profile',
    component: Profile,
  },
  // {
  //   link: '/profile/:tab(update|resetpassword)',
  //   label: 'Profile',
  //   component: Profile,
  // },
  {
    link: '/post/:tab(type|description|addfile|success)',
    label: 'CreatePost',
    component: CreatePost,
  },
  {
    link: '/puzzles',
    label: 'Puzzles',
    component: Puzzles,
  },
  {
    link: '/archive',
    label: 'Archive',
    component: Archives,
  },

  {
    link: '/message',
    label: 'Message',
    component: Message,
  },
  {
    link: '/startmessage',
    label: 'StartMessage',
    component: StartMsg,
  },
  {
    link: '/replymessage',
    label: 'ReplyMessage',
    component: ReplyMsg,
  },
  {
    link: '/replymessage/reply/:id',
    component: Reply,
  },
]
export const unAuthenticatedRoutes = [
  {
    link: '/login',
    label: 'Login',
    component: Login,
  },
  {
    link: '/express-with-artzage',
    label: 'Socialmedia',
    component: Socialmedia,
  },
  {
    link: '/artists/:tab(articles|artworks|followers)/:id',
    label: 'Artists',
    component: PublicProfile,
  },
  {
    link: '/artworks',
    label: 'Artworks',
    component: ArtworkList,
  },
  {
    link: '/artworks/category/:category',
    label: 'Artworks',
    component: ArtworkList,
  },
  {
    link: '/artworks/:title',
    label: 'Artworks',
    component: ArtworkDetails,
  },
  {
    link: '/articles',
    label: 'Articles',
    component: ArticleList,
  }, {
    link: '/articles/category/:category',
    label: 'Articles',
    component: ArticleList,
  },
  {
    link: '/articles/:title',
    label: 'Articles',
    component: ArticleDetails,
  },
  {
    link: '/',
    label: 'Home',
    component: Home,
  },
  {
    link: '/auth/verifyEmail',
    label: 'Verification',
    component: Verification,
  },
  {
    link: '/register',
    label: 'Register',
    component: Register,
  },
  {
    link: '/forgotpassword',
    label: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    link: '/auth/resetpassword',
    label: 'ResetPassword',
    component: ResetPassword,
  },

  {
    link: '/faq',
    label: 'Faq',
    component: Faq,
  },
  {
    link: '/privacypolicy',
    label: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    link: '/shippingpolicy',
    label: 'ShippingPolicy',
    component: ShippingPolicy,
  },
  {
    link: '/refundpolicy',
    label: 'RefundPolicy',
    component: RefundPolicy,
  },
  {
    link: '/termsofservice',
    label: 'Terms',
    component: Terms,
  },
  {
    link: '/ourstory',
    label: 'AboutUs',
    component: AboutUs,
  },
  {
    link: '/joinus',
    label: 'WorkWithus',
    component: WorkWithus,
  },
  {
    link: '/connectwithus',
    label: 'Contact',
    component: Contact,
  },
  {
    link: '/stayposted',
    label: 'Announcements',
    component: Announcements,
  },
  {
    link: '/data-deletion-instructions',
    label: 'Datadeletion',
    component: Datadeletion,
  },
  {
    link: '/purchases/artworks/:id',
    label: 'Purchases',
    component: Purchases,
  },
  {
    link: '/goingson',
    label: 'Events',
    component: Events
  },
  {
    link: '/goingson/:id',
    label: 'Events',
    component: EventsDetail
  }
]